.product {
  @include span(10 of 10 last);
  margin-bottom: 20px;
  //@include container();

  &--left {
    @include span(4 of 10);
    @media (max-width: 768px) {
      @include span(5 of 10);

    }
    @media (max-width: 610px) {
      @include span(10 of 10 last);
    }
    //background-color: coral;
    //min-height: 50px;
  }

  &--title {
    margin-top: 0;
    @media (max-width: 610px) {
      text-align: center;
    }
  }

  &--image {
    display: block;
    //height: 362px;
    padding-bottom: span(13);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: white;
    border: 1px solid $c-input-border;
  }


  &--config {
    @include span(6 of 10 last);
    @media (max-width: 768px) {
      @include span(5 of 10 last);
    }
    @media (max-width: 610px) {
      @include span(10 of 10 last);
    }
    //padding-left: gutter();
  }

  &--price {
    @include span(6 of 6 last);
    margin-bottom: gutter(6);
    border-bottom: 1px solid $c-input-border;
    & > p:first-child {
      margin-top: 0;
    }
  }

  &--code {
    font-size: 18px;
    font-weight: 400;
    color: $c-base;
    margin-top: 6px;
    float: right;

    @media (max-width: 610px) {
      display: block;
      float: none;
    }
  }

  &--money {
    font-size: 36px;
    font-weight: 700;
    line-height: 1;
    margin: 0;
    color: $c-base;
  }
  &--mention {
    font-style: italic;
  }

}

.config {

  &--standard {
    @include span(3 of 6);
    @media (max-width: 768px) {
      @include span(6 of 6 last);
    }
  }

  &--customizer {
    @include span(3 of 6 last);
    @media (max-width: 768px) {
      @include span(6 of 6 last);
    }
  }

  &--title {
    margin-top: 0;
    margin-bottom: 5px;
    text-transform: uppercase;
    color: $c-base;
    font-size: 18px;
  }

  &--label {
    display: inline-block;
    font-size: 16px;
    line-height: 1;
    padding: 19px 0 5px 0;
    width: 100%;
  }
  &--field {
    width: 100%;
    margin-bottom: 4px;
  }
  &--field-small {
    @extend .l-left;
    width: 50px;
    text-align: center;
    padding: 0;
  }
  &--field-obs {
    font-size: 12px;
    font-style: italic;
    margin: 2px 0 0 55px;
  }
  &--radio-group {
    @include container(2);
    //@include span(2 of 3 last);
  }
  &--radio {
    font-size: 16px;
    display: inline-block;
    //line-height: 1 !important;
    //padding-top: 18px;
    @include span(1 of 2);
    //margin-right: gutter(3);

    &:last-child {
      @include span(1 of 2 last);

      //margin-right: 0;
      //@include span(1 of 2 last);
    }
  }
}

.standard {
  &--total-price {
    color: $c-base;
    font-size: 22px;
    font-weight: 600;
    line-height: 1;
    margin: 10px 0 20px 0;

    small {
      color: $c-base-font;
      font-size: 14px;
    }
  }

  &--offer-button {
    width: 100%;
    text-align: center;
  }
}

.customizer {
  &--offer-button {
    width: 100%;
    text-align: center;
    @media (max-width: 360px) {
      padding: 0 5px;
      font-size: 13px;
    }
  }
}

.gallery {
  margin: 20px 0 40px 0;

  &--title {
    font-size: 16px;
    //font-weight: 400;
    font-weight: 600;
    margin: 20px 0;
    border-bottom: 1px solid $c-input-border;
    padding-bottom: 2px;
  }

  &--images {
    //@include container(4);
    a {
      width: 100%;
      display: block;
      height: 80px;
      //padding-bottom: span(1 of 4);
      //@include gallery(1 of 4);
      border: 1px solid $c-input-border;
      background-size: cover;
      background-color: white;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}

.product-images {
  margin-left: -10px;
  margin-right: -10px;
  position: relative;
  &--item {
    padding: 0 10px;
  }

}
.similar-products {
  margin-left: -10px;
  margin-right: -10px;
  //margin-top: -5px;
  position: relative;
}
.similar-product {
  padding: 0 10px;
  //@include gallery(3 of 12);
  &--container {
    border: none;
  }
  &--image {
    height: 200px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: white;
    border: 1px solid $c-input-border;
    transition: all .3s;
    margin-top: 5px;
  }
  &:hover .similar-product--image {
    -webkit-box-shadow: 0 0 5px 1px rgba(0,0,0,0.15);
    box-shadow: 0 0 5px 1px rgba(0,0,0,0.15);
    //transform: scale(1.01);
  }

    //a {
    //  height: 200px;
    //  background-size: contain;
    //}
  //}
}
.slick-prev {
  position: absolute;
  top: -50px;
  right: 10px;
  height: 30px;
  line-height: 30px;
  width: 30px;
  padding: 0;
  text-align: center;
  margin-right: 40px;
}
.slick-next {
  @extend .slick-prev;
  margin-right: 0;
}
//.slick-disabled {
//  visibility: hidden;
//}

.description {
  @extend .gallery;

  &--title {
    @extend .gallery--title;
  }
  &--content {
    p:first-child {
      margin-top: 0;
    }
  }

  &--content {
    min-height: 345px;
    position: relative;
    overflow: hidden;

    &.hidden {
      height: 325px;

      .description--more {
        position: absolute;
        bottom: 0;
        padding-top: 40px;
      }
    }
  }

  &--more {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+50,1+100 */
    background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */


    position: static;
    width: 100%;
    font-size: 22px;
    text-align: center;

    //display: block;

    &-button {
      cursor: pointer;
      border: none;
    }
  }

  &--button-group {
    @include container(4);
    margin-top: 30px;

    .button {
      @include gallery(2 of 4);
      text-align: center;
      padding: 0 5px;
    }
  }
}

.hidden-inputs {
  display: none;
}

.after-config {
  clear: both;
  padding-top: 20px;
  position: relative;
}

.total {
  @include span(3 of 6);

  position: absolute;
  top: 0;
  transform: translateY(-100%);

  @media (max-width: 768px) {
    @include span(6 of 6 last);

    position: static;
    transform: translateY(0);
  }

  &.hidden {
    display: none;
  }
  .product--mention {
    margin-bottom: 5px;
  }
}

.offer-disclaimer {
  margin-top: 30px;
}

.price {
  &--custom {

  }

  &--standard {
    font-size: 20px;
    color: #999;
    font-weight: 400;
    &.hidden {
      display: none;
    }
    .price--value {
      font-size: 22px;
      color: #666;
    }
    .price--tva {
      font-size: 14px;
      color: #999;
    }
  }

  &--tva {
    font-size: 20px;
    color: #676767;
    font-weight: 400;
  }
}
.disclaimer {
  margin-top: 40px;
  &--title {
    @extend .gallery--title;
    margin-bottom: 14px;
  }
}


.delivery-zone-option {
  &--name {
    font-size: 14px;
    font-weight: 600;
  }
  &--code {
    float: right;
    font-size: 10px;
  }
  &--description {
    display: block;
    padding: 5px 0;
    font-style: italic;
    color: #999;
  }
}