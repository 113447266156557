.top-bar {
  background-color: $c-base;

  @media (max-width: 700px) {
    width: 100%;
    position: fixed;
    z-index: 9990;
  }


  &--menu {
    list-style: none;
    padding: 0;
    font-size: 0;
    margin-right: -10px;

    @media (max-width: 920px) {
      float: none;
      text-align: center;
    }
    @media (max-width: 700px) {
      display: none;
    }

    li {
      display: inline-block;
      font-size: 14px;
      margin: 10px;
    }

    a {
      font-weight: 400;
      display: inline-block;
      color: white;
      padding: 0;
      border-bottom-color: rgba(255, 255, 255, 0);

      &:hover {
        border-bottom-color: rgba(255, 255, 255, 1);
      }
    }
  }
}