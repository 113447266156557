.portofolio {
    &--images {
        @include container(100%);
        margin-bottom: 20px;
        margin-top: 40px;
        @media (max-width: 400px) {
            //overflow: auto;
        }

    }
    &--image {
        @include gallery(3 of 12);
        height: 0;
        padding-bottom: span(3);
        background-size: cover;
        border: 1px solid $c-input-border;
        margin-bottom: gutter(12);
        display: block;

        @media (max-width: 700px) {
            @include gallery(6 of 12);
            padding-bottom: span(6);
        }
        @media (max-width: 400px) {
            @include gallery(12 of 12);
            padding-bottom: 100%;
            width: 100%;
            float: none;
        }
    }
}