.faq {
  &--form {
    .l-left-half {
      @media (max-width: 600px) {
        @include span(12 of 12);
      }
    }
    .l-right-half {
      @media (max-width: 600px) {
        @include span(12 of 12);
      }
    }
  }
}