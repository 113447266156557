.parsley {
  &-errors-list {
    list-style: none;
    padding: 0;
    font-size: 14px;
    font-style: italic;
    color: $c-error;
    margin: 0;
    transform: translateY(5px);
    line-height: 1;
  }

  &-error {
    -webkit-box-shadow: 0 0 5px 1px $c-error;
    box-shadow: 0 0 5px 1px $c-error;
  }
}