.partners {
  border: 1px solid $c-input-border;
  background-color: white;
  margin-bottom: gutter(3);
  padding: 20px;
  text-align: left;

  &--title {
    margin: 0 0 10px 0;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
  }

  &--links {
    padding: 0;
    list-style: none;
    margin-bottom: 0;

    li {
      border-bottom: 1px solid $c-input-border;
      &:first-child {
        border-top: 1px solid $c-input-border;
      }
    }

    a {
      color: $c-base-font;
      font-weight: 400;
      line-height: 40px;
      display: block;
      border: none;
      &:hover {
        color: $c-base;
        font-weight: 700;

      }
    }
  }
}