.mobile-menu {
  list-style: none;
  font-size: 0;
  text-align: center;
  padding: 11px 0 8px 0;
  margin: 0 -10px;
  overflow: auto;
  display: none;
  @media (max-width: 700px) {
    display: block;
  }

  li {
    font-size: 14px;
    //display: inline-block;
    @include gallery(1 of 4);
  }
  a {
    color: white;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 400;
  }
  i {
    display: block;
    font-size: 20px;
  }
}

.responsive-menu {
  position: fixed;
  background-color: white;
  top: 0;
  bottom: 0;
  overflow-y: scroll;
  z-index: 9998;
  width: 100%;
  border-bottom: 2px solid $c-base;
  transform: translateY(-100%);
  visibility: hidden;
  transition: transform .5s, visibility .1s .5s;

  &.visible {
    transition: transform .5s, visibility .1s;
    transform: translateY(0);
    visibility: visible;
  }
  ul {
    padding: 20px;
    list-style: none;
    padding-left: 50px;

    ul {
      padding: 0;
    }
  }
  li {
    line-height: 40px;
    border-bottom: 1px solid $c-input-border;

    &.responsive-menu--category {
      border: none;
      & > a {
        font-weight: 700;

      }
    }
  }
  &--header {
    padding-left: 0;
    margin-left: -30px;
    padding-bottom: 10px;
    font-size: 18px;
    text-transform: uppercase;
  }

  &--subcategory {
    &:first-child {
      border-top: 1px solid $c-input-border;
    }
  }

  a {
    line-height: 40px;
    font-weight: 400;
    color: $c-base-font;
  }
}

.close-burger {
  &--button{
    float: right;
    //padding-right: 30px;
    color: #999;
    font-weight: 400;
    font-size: 12px;
    text-transform: lowercase;
  }
}

.close-products {
  &--button{
    float: right;
    //padding-right: 30px;
    color: #999;
    font-weight: 400;
    font-size: 12px;
    text-transform: lowercase;
  }
}

.mobile-search {
  &.visible {
    transform: translateY(0);
    visibility: visible;
  }

  &--form {
    padding: 0 20px;
  }
  &--field {
    width: 100%;
  }
  &--button {
    margin-top: 10px;
    float: right;
  }

  .close-search {
    &--button {
      float: right;
      //padding-right: 30px;
      color: #999;
      font-weight: 400;
      font-size: 12px;
      text-transform: lowercase;
    }
  }
}