.selectize-control {
  //height: 42px;
}
.selectize-input {
  border: 1px solid $c-input-border;
  border-radius: 0;
  box-shadow: none;
  padding: 11px;
}
.selectize-input.focus {
  box-shadow: none;
}
.selectize-dropdown-content {
  max-height: 300px;
}


.selectize-control.multi .selectize-input.has-items {
  padding: 10px 8px 5px;
}

.tapiserie-type .selectize-dropdown [data-selectable],
.tapiserie-color .selectize-dropdown [data-selectable],
.handle-color .selectize-dropdown [data-selectable] {
  padding: 0;
}
.select--images {
  display: block;
  width: 100%;
  height: 75px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  //margin: 5px 0;
}
.select--text {
  display: block;
  background-color: rgba(0, 0, 0, .6);
  color: white;
  text-align: center;
  line-height: 1.5;
}
//.select2::selection {
//  background: $c-input-border;
//}
//.select2::-moz-selection {
//  background: $c-input-border;
//}