.logo-menu {

  padding: 30px 0;

  @media (max-width: 700px) {
    padding-top: 95px;
  }
}

.header--logo {
  border: none;

  @media (max-width: 920px) {
    display: block;
    text-align: center;
  }



  img {
    width: 300px;
    @media (max-width: 350px) {
      width: 100%;
    }
  }
}