
//$grid-padding: 1em;
.l-wrap {
  //width: 100%;
  //max-width: 1200px;
  //margin: 0 auto;
  box-sizing: border-box;
  @include container(1260px);
  padding-left: 20px;
  padding-right: 20px;
  min-width: 320px;
}
.l-contain {
  overflow: auto;
}
.l-right {
  float: right;
}
.l-left {
  float: left;
}

.l-left-half {
  @include span(5 of 10);
}
.l-right-half {
  @include span(5 of 10 last);
}

.left-sidebar {
  margin-top: gutter();
  @include span(3);

  @media (max-width: 1060px) {
    display: none;
  }
}

.main {
  margin-top: gutter();
  //margin-bottom: 50px;
  @include span(10 last);
  @media (max-width: 1060px) {
    @include span(10 of 10 last);
  }
}
