.auth {
  &--logo {
    width: 100%;
    max-width: 400px;
    padding: 0 40px;
    margin: 40px auto;
  }
  &--content {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 5px 40px 20px 40px;
    background-color: $c-base;
    color: white;
  }

  &--title {
    margin-bottom: 0;
  }

  &--form {
    width: 100%;
    label {
      display: block;
      font-size: 14px;
    }
    input {
      display: block;
      width: 100%;
    }
    input[type="checkbox"] {
      display: none;
    }
    input[type="checkbox"] {
      &:checked + label::before {
        color: white;
      }
    }
    button {
      background: white;
      color: $c-base;
      float: right;
    }
  }
  &--buttons {
    overflow: auto;
    margin-top: 20px;
  }

  &--password-reset-link {
    display: inline-block;
    color: white;
    font-weight: 400;
    border-bottom: 1px solid white;
    margin-top: 10px;
    &:hover {
      border-bottom-color: white;
    }
  }
}