.sort {
  font-size: 14px;
  font-weight: 400;
  @media (max-width: 700px) {
    margin-top: 10px;
    float: none;
  }
  &--title {
    display: inline-block;
    line-height: 40px;
    vertical-align: middle;
    margin: -14px 10px 0 0;
    @media (max-width: 700px) {
      display: none;
    }
  }
  &--form {
    display: inline-block;
    vertical-align: middle;
    width: 170px;

    @media (max-width: 700px) {
      width: 100%;
    }
  }
  &--select {
    //height: 42px;
  }
  &--option {
    i {
      display: inline-block;
      margin-right: 5px;
      font-size: 14px;
      color: #999;
      width: 15px;
    }
  }
}
.filter {
  @media (max-width: 700px) {
    margin-top: 10px;
    float: none;
  }
  &--title {
    @extend .sort--title;
  }
  &--form {
    @extend .sort--form;
    width: 250px;

    @media (max-width: 700px) {
      width: 100%;
    }

  }
  &--option {
    @extend .sort--option;

    i {
      display: none;
    }
  }
  &--select {
    //height: 42px;
    .selectize-dropdown .optgroup-header {
      font-weight: 700;
    }
    .option {
      padding-left: 20px;
    }
  }
}