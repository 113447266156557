.slider {
  background-image: url("/img/slider.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 300px;

  @media (max-width: 700px) {
    display: none;
  }

  &--title {
    margin: 0;
    font-size: 28px;
    text-align: center;
    line-height: 1.4;
    padding: 26px 0;
    font-weight: 400;
    color: white;
    background-color: rgba(0, 0, 0, .4);
    position: relative;
    top: 50%;
    transform: translateY(-45%);
  }
}