.newsletter {
  border: 1px solid $c-input-border;
  background-color: white;
  margin-bottom: gutter(3);
  padding: 20px;
  text-align: center;

  &--title {
    margin: 0 0 10px 0;
    font-size: 16px;
    font-weight: 300;

    strong {
      font-weight: 600;
    }
  }

  &--fields {
    margin: 10px 0;
    width: 100%;
  }

  &--button {
    margin-top: 10px;
  }

  .parsley-errors-list {
    transform: translateY(-5px);
    text-align: left;
  }
}