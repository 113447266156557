.footer{
  padding: 20px 0 20px 0;
  background-color: $c-light-gray;
  font-size: 13px;
  margin-top: 30px;
  @media (max-width: 960px) {
  }

  .l-wrap {
    position: relative;

  }

  &--left {
    @include span(19 of 26);
    margin-top: 10px;
    @media (max-width: 980px) {
      @include span(13 of 13);
    }
  }
  &--right {
    //@include span(7 of 26 last);
    //margin-bottom: -63px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    @media (max-width: 980px) {
      position: static;
      transform: translateY(0);
      //@include span(13 of 13);
    }
  }

  &--menu {
    list-style: none;
    padding: 0 0 15px 0;
    margin: 0 0 0 -10px;
    font-size: 0;
    @media (max-width: 980px) {
      text-align: center;
    }
    @media (max-width: 980px) {
      display: none;
    }

    li {
      font-size: 14px;
      display: inline-block;
    }
    a {
      font-weight: 400;
      color: $c-base-font;
      border-bottom: 1px solid rgba(red($c-base), green($c-base), blue($c-base), 0);
      padding: 0 5px;
      margin: 0 5px;
      &:hover {
        color: $c-base;
        border-bottom: 1px solid rgba(red($c-base), green($c-base), blue($c-base), 1);

      }
    }
  }

  &--copyright {
    font-weight: 400;
    border-top: 1px solid $c-input-border;
    @media (max-width: 980px) {
      text-align: center;
    }
    p {
      margin: 15px 0 0 0;
    }

    a {
      color: $c-base-font;
      font-weight: 400;
      &:hover {
        color: $c-base;
      }
    }
  }

  &--social {
    list-style: none;
    font-size: 0;
    padding: 0;
    text-align: right;
    //transform: translateY(19px);
    margin-right: 10px;


    @media (max-width: 980px) {
      text-align: center;
      padding-bottom: 10px;
      margin-bottom: 0;
    }

    li {
      font-size: 22px;
      display: inline-block;
    }
    a {
      padding: 0 5px;
      margin: 0 5px;
      color: #999;
      border: none;

      &:hover {
        color: $c-base;
      }
    }
  }
}