.button,
button {
  display: inline-block;
  text-decoration: none;
  background-color: $c-base;
  font-weight: 600 ;
  color: white;
  text-transform: uppercase;
  padding: 0 20px;
  line-height: 40px;
  border: 1px solid $c-base;
  cursor: pointer;
  transition: background .3s;

  &:hover {
    background-color: $c-base-darker;
  }

  &--icon {
    font-size: 20px;
    vertical-align: middle;
    margin-right: 10px;
  }
  &.button-secondary {
    margin-left: 10px;
    background-color: $c-light-gray;
    color: $c-base-font;
    border-color: $c-input-border;
    &:hover {
      background-color: $c-input-border;
    }
  }

}
.button-block {
  display: block;
  width: 100%;
  margin: 20px 0;
}

.button-box {
  margin: 20px 0;
}
.button-outline {
  @extend .button;
  background-color: #f7f7f7;
  border-color: $c-input-border;
  color: $c-base-font;

  &:hover {
    background-color: #e7e7e7;
    border-bottom-color: $c-input-border;
  }

  .button--icon {
    color: $c-base;
  }

}

.button-small {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
  color: white;
  padding: 8px;
  border: none;
  transition: background-color .3s;
  line-height: 1;

  &--view {
  //  green
    @extend .button-small;
    background-color: #00ACC1;

    &:hover {
      background-color: #0097A7;
    }
  }

  &--edit {
  //  blue
    @extend .button-small;
    background-color: #7CB342;
    &:hover {
      background-color: #689F38;
    }
  }

  &--delete {
  //  red
    @extend .button-small;
    background-color: #D84315;
    &:hover {
      background-color: #BF360C;
    }
  }
}

.button-reverse {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
  color: white;
  padding: 8px;
  border: none;
  transition: background-color .3s, color .3s;
  background-color: transparent;
  line-height: 1;

  &--edit {
    //  blue
    @extend .button-reverse;
    color: #7CB342;
    &:hover {
      color: white;
      background-color: #689F38;
    }
  }

  &--delete {
    //  red
    @extend .button-reverse;
    color: #D84315;
    &:hover {
      background-color: #BF360C;
      color: white;
    }
  }
}