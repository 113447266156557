.main-menu {
  margin-top: 18px;
  font-size: 0;
  padding: 0;
  margin-right: -10px;
  @media (max-width: 920px) {
    float: none;
    text-align: center;
  }
  @media (max-width: 700px) {
    display: none;
  }

  li {
    display: inline-block;
    font-size: 14px;
    text-transform: uppercase;
    margin: 0 10px;
  }
  a {
    font-weight: 400;
    color: #5a5a5a;

    &:hover {
      border-bottom-color: #5a5a5a;
    }
  }
}