.carousel {
  //@include span(9 of 10);
  //margin-left: span(1);
  position: relative;
  margin-bottom: 50px;



  &--item {
    padding: 60px 0 60px 0;
    margin: 0 30px;
    border: none;
    transition: all .3s;

    @media (max-width: 600px) {
      padding-left: 50px;
      padding-right: 50px;
    }
    @media (max-width: 430px) {
      padding-left: 0;
      padding-right: 0;
    }

    &.slick-current {
      transform: scale(1.3);
    }

    &:hover .carousel--price {
      transform: translateY(0);
    }


  }
  &--image {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: white;
    border: 1px solid $c-input-border;
    height: 230px;
    position: relative;
    overflow: hidden;

    @media (max-width: 800px) {
      height: 140px;
    }
  }
  &--price {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.9);
    line-height: 60px;
    color: $c-base-font;
    transform: translateY(100%);
    transition: transform .3s;
    font-size: 16px;
    border-top: 1px solid $c-input-border;
  }

  .slick-list {
    //overflow: initial;
    //overflow-x: hidden;
    //padding-top: 40px;
    //padding-bottom

  }
  .slick-prev {
    position: absolute;
    left: 0;
    right: auto;
    height: 100%;
    top: 0;
    background-color: white;
    border: none;
    color: $c-base-font;
    z-index: 9000;
    text-align: right;
    width: 60px;
    @media (max-width: 430px) {
      width: 30px;
    }

    i {
      border: 1px solid $c-input-border;
      width: 30px;
      line-height: 30px;
      text-align: center;
    }
    &:focus {
      outline: none;
    }

  }
  .slick-next {
    @extend .slick-prev;
    right: 0;
    left: auto;
    margin-right: 0;
    text-align: left;
  }

  .slick-disabled {
    visibility: hidden;
  }
}