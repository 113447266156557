.product-gallery {
  //@include layout(100% 9);
  @include container(9);
}
.product-tile {
  @include gallery(3 of 9);
  margin-bottom: 20px;
  border: none;
  display: block;
  @media (max-width: 600px) {
    @include gallery(2 of 4);
  }
  @media (max-width: 400px) {
    @include gallery(4 of 4);

    width: 100%;
    float: none;
  }

  &--image {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    border: 1px solid $c-input-border;
    background-color: white;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    overflow: hidden;
    transition: all .3s;
  }
  &:hover .product-tile--image {
    -webkit-box-shadow: 0 0 5px 1px rgba(0,0,0,0.15);
    box-shadow: 0 0 5px 1px rgba(0,0,0,0.15);
    //transform: scale(1.01);
  }
  &--price {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.9);
    line-height: 60px;
    color: $c-base-font;
    transform: translateY(100%);
    transition: transform .3s;
    font-size: 16px;
    border-top: 1px solid $c-input-border;
  }
  &:hover .product-tile--price {
    transform: translateY(0);
  }

  &--name {
    font-weight: 400;
    text-align: center;
    margin: 10px 0 0;
  }

  &--code {
    margin: 0;
    text-align: center;
    color: #a2a2a2;
  }
}

