.search-bar {
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  background-color: #f7f7f7;
  padding: 20px 0;
  @media (max-width: 700px) {
    display: none;
  }
}

.show-products-button {
  display: none;
  @media (max-width: 1060px) {
    display: block;
    @include span(3 of 13);
    text-align: center;
  }
  @media (max-width: 870px) {
    @include span(4 of 13);
  }
  a {
    width: 100%;
  }
  i {
    margin-right: 5px;
  }
}

.catalog {
  @include span(7 of 13);
  @media (max-width: 1060px) {
    @include span(3 of 13);
  }
  @media (max-width: 870px) {
    @include span(4 of 13);
  }


  &--icon {
    font-size: 40px;
    line-height: 1;
    color: $c-base;
    @extend .l-left;
    @media (max-width: 1060px) {
      display: none;
    }
  }

  &--info {
    @extend .l-left;

    margin-left: 20px;

    @media (max-width: 1060px) {
      display: none;
    }
  }

  &--title {
    font-size: 16px;
    font-weight: 300;
    margin: 0;

    strong {
      font-weight: 600;
    }
  }

  &--text {
    margin: 0;
  }

  &--button {
    margin-left: 20px;
    @extend .l-left;
    @media (max-width: 1060px) {
      margin-left: 0;
      width: 100%;
      text-align: center;
    }

  }
}


.search {
  @include span(6 of 13 last);
  font-size: 0;
  position: relative;
  @media (max-width: 1060px) {
    @include span(7 of 13 last);
  }
  @media (max-width: 870px) {
    @include span(5 of 13 last);
  }
  @media (max-width: 700px) {
    display: none;
  }

  input {
    font-size: 14px;
  }
  &--field {
    @include span(6 of 6);
  }
  &--category {
    position: absolute;
    right: 50px;
    border-right: none;
    display: none;
    //transform: translateX(-100%);
  }
  &--button {
    position: absolute;
    right: 0;
    padding: 0;
    width: 50px;
    text-align: center;
    vertical-align: top;
    font-size: 18px;
    background-color: white;
    border-color: $c-input-border;
    color: $c-input-border;
    transition: color .3s;

    &:hover {
      background: white;
      color: $c-base;
    }
  }
}
