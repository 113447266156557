@import 'normalize';

* {
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #676767;
}
p {
  line-height: 1.7;
}
li {
  line-height: 1.7;
}
h1 {
  font-weight: 600;
  font-size: 24px;
}
h2 {
  font-weight: 600;
}
h3 {
  font-weight: 600;
}

img {
  width: 100%;
}

a {
  color: $c-base;
  font-weight: 600;
  text-decoration: none;
  padding-bottom: 2px;
  border-bottom: 1px solid rgba(red($c-base), green($c-base), blue($c-base), 0);
  transition: color .3s;

  &:hover {
    border-bottom-color: rgba(red($c-base), green($c-base), blue($c-base), 1);
    //color: $c-base;
  }
}

label {

}

input, textarea {
  display: inline-block;
  border: 1px solid $c-input-border;
  padding: 5px 13px;
}
input {
  padding: 0 0 0 13px;
  line-height: 40px;
  &.parsley-error {
    margin-bottom: 0;
  }
  & + .parsley-errors-list {
    //margin-bottom: 0;
    margin-bottom: 0;
  }
}
textarea {
  width: 100%;
  min-height: 120px;
  display: block;
  margin-bottom: 20px;
  &.parsley-error {
    margin-bottom: 0;
  }
  & + .parsley-errors-list {
    //margin-bottom: 0;
    margin-bottom: 15px;
  }
}

input[type="checkbox"],
input[type="radio"] {
  display: none;

  & + label {
    line-height: 40px;
    vertical-align: middle;

    &:before {
      font-family: "FontAwesome";
      color: rgba(red($c-base), green($c-base), blue($c-base), 0);;
      display: inline-block;
      line-height: 20px;
      vertical-align: middle;
      text-align: center;
      width: 20px;
      height: 20px;
      border: 1px solid $c-input-border;
      margin-right: 5px;
      transition: color .4s;
    }
  }

  &:checked + label::before {
    color: rgba(red($c-base), green($c-base), blue($c-base), 1);
  }
  &:disabled + label::before {
    background-color: $c-light-gray;
  }
}


input[type="checkbox"] {
  & + label::before {
    content: "\f00c";
    font-size: 18px;

  }
}

input[type="radio"] {
  & + label::before {
    border-radius: 10px;
    content: "\f111";
    font-size: 12px;
  }
}
