.pagination {
  padding-left: 0;
  list-style: none;
  text-align: center;

  li {
    display: inline-block;
    width: 30px;
  }
  a {
    &:hover {
      font-weight: 700;
    }
  }
  .disabled {
    display: none;
  }
}