.gallery {
  @include container(auto);

  &--item {
    @include gallery(3 of 12);
    text-align: center;
    border: none;
    margin-top: 10px;
    @media (max-width: 700px) {
      @include gallery(6 of 12);
    }
    @media (max-width: 400px) {
      @include gallery(12 of 12);
      width: 100%;
      float: none;
    }

    &--image {
      width: 100%;
      height: 0;
      padding-bottom: 70%;
      border: 1px solid $c-input-border;
      background-color: white;
      background-size: cover;
      background-position: center;
    }

    &--title {
      line-height: 1.3;
      font-weight: 400;
      color: #333;
    }

    &:hover {
      .gallery--item--title {
        color: $c-base;
        //font-weight: 600;
      }
    }
  }
}