$c-base: #990000;
//$c-base: #0493a1;
$c-base-darker: #700;

$c-input-border: #d7d7d7;

$c-base-font: #676767;

$c-light-gray: #f7f7f7;

$c-error: #D84315;

$susy: (
  columns: 13,
  gutter: 1/4,
  debug: (
    //image: show
  )
);