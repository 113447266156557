.contact {
  padding-top: 30px;
  margin-bottom: 60px;

  &--text {
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
  }

  iframe {
    margin-top: 30px;
    width: 100%;
    height: 200px;
  }

  &--title {
    text-align: center;
    text-transform: uppercase;
    margin: 40px 0 10px 0;
  }
  &--columns {
    @include container(100%);
  }
  &--column {
    @include span(4 of 12);
    text-align: center;
    @media (max-width: 900px) {
      @include span(12 of 12);
      text-align: center;
    }

    &:first-child {
      text-align: right;
      padding-right: 20px;
      @media (max-width: 900px) {
        text-align: center;
        padding-right: 0;
      }
    }
    &:last-child {
      text-align: left;
      padding-left: 20px;
      @include span(4 of 12 last);
      @media (max-width: 900px) {
        @include span(12 of 12 last);
        text-align: center;
        padding-left: 0;
      }
    }


  }

  input[type="text"] {
    width: 100%;
    display: block;
    margin: 20px 0;
  }
  textarea {
    display: block;
    margin: 20px 0 0 0;

  }

  button {
    margin: 20px 0;
  }

  .parsley-errors-list {
    text-align: left;
  }
  .parsley-error {
    margin-bottom: 0 !important;
  }
}