.overlay {
  background-color: rgba(0, 0, 0, .4);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9000;
  transform: translateZ(0);
  transition: opacity .5s;
  opacity: 0;
  display: none;

  &.visible {
    opacity: 1;
  }
}

.modal {
  background-color: white;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  max-width: 600px;
  min-height: 200px;
  padding: 20px 20px 82px 20px;


  &--title {
    margin: 0;
    font-size: 28px;
  }

  &--close {
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
    border: 0;
    padding: 10px;
  }

  p {
    margin-bottom: 0;
  }
  &--footer {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 20px;
    width: 100%;
    text-align: right;

    a {
      margin-left: 20px;
    }
  }


}