.category-menu {
  border: 1px solid $c-input-border;
  padding: 20px;
  background-color: white;
  margin-bottom: gutter(3);

  &--title {
    font-weight: 400;
    text-transform: uppercase;
    font-size: 16px;
    margin: 0;
    //padding-bottom: 5px;
    //border-bottom: 1px solid $c-input-border;
    i {
      font-size: 14px;
      margin-right: 10px;
      transform: translateY(-1px);
    }
  }

  &--category {
    padding: 0;
    list-style: none;
    font-size: 16px;
    border-top: 1px solid $c-input-border;
    margin-bottom: 0;

    a {
      border-bottom: none;
      font-weight: 400;
      color: $c-base-font;
      display: block;
      border-bottom: 1px solid $c-input-border;
      padding: 0;
      line-height: 40px;
      transition: color .3s;

      &:hover {
        color: $c-base;
        font-weight: 700;
      }
    }
  }

  &--subcategory {
    list-style: none;
    padding-left: 20px;
    font-size: 14px;

    li {
      //margin: 10px;
      //&:last-child {
      //  a {
      //    border-bottom: none;
      //  }
      //}
    }
  }
}